<!--
 * @Date: 2023-12-06 11:28:18
 * @Author: ym
 * @LastEditors: yanminw 2398988384@qq.com
 * @LastEditTime: 2024-04-17 11:17:11
 * @description: 
 * @FilePath: \yf_car\src\views\result.vue
-->
<template>
  <div class="parkingcoupons-pay">
    <!-- <navBar title="优惠券绑定状态" /> -->
    <div class="g-tips-box">
      <img :src="status == 0 ? require('@/assets/images/success.png') : require('@/assets/images/fail.png')" />
      <strong v-if="status == 0">绑定成功</strong>
      <strong v-if="status == 1">绑定成功</strong>
      <strong v-if="status == 1">出场时自动减免</strong>
      <strong v-if="status == 2">页面已失效</strong>
      <strong v-if="status == 2">绑定优惠券, 请重新扫码</strong>
      <!-- <div class="g-foot-box">
      <p class="u-updata-tips">若不抬杆，请拨打停车场服务热线</p> -->
      <!-- <footdiv></footdiv> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      status: 1,
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    this.status = this.$route.query.status
  },
  mounted() {
    document.body.style.backgroundColor = '#f5f5f5'
  },
  beforeDestroy() {
    document.body.style.backgroundColor = '#fff'
  },
}
</script>
<style lang="scss" scoped>
.g-tips-box {
  width: 100%;
  height: auto;
  background: #fff;
  display: block;
  overflow: hidden;
  padding: 1.14rem 0;
  margin: 0;
}

.g-tips-box img {
  width: 2rem;
  height: auto;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.g-tips-box strong {
  width: 100%;
  height: 0.44rem;
  line-height: 0.44rem;
  font-size: 0.38rem;
  font-weight: normal;
  color: #222;
  text-align: center;
  margin: 0.38rem 0 0;
  display: block;
  overflow: hidden;
}
</style>
